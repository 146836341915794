
import { clearDigits, formatDate } from '../utils/formatters'

export const PF = 'Pessoa Física'
export const PJ = 'Pessoa Jurídica'

const DEFAULT_API_DATA = {
  abertura: '',
  atividade_principal: [],
  atividades_secundarias: [],
  bairro: '',
  billing: null,
  capital_social: 0,
  cep: '',
  cnpj: '',
  complemento: '',
  data_situacao: '',
  data_situacao_especial: '',
  efr: '',
  email: '',
  extra: [],
  fantasia: '',
  logradouro: '',
  motivo_situacao: '',
  municipio: '',
  natureza_juridica: '',
  nome: '',
  numero: '',
  porte: '',
  qsa: [],
  situacao: '',
  situacao_especial: '',
  status: '',
  telefone: [],
  tipo: '',
  uf: '',
  ultima_atualizacao: ''
}

const DEFAULT_BANK_DATA = {
  bank: '',
  agency: '',
  account: '',
  operation: '',
  pix_type: '',
  pix_key: ''
}

export const DEFAULT_COMPANY = {
  id: null,
  accounting_status: '',
  cnpj: '',
  created_at: '',
  extra: null,
  name: '',
  owner_doctor_id: null,
  type: '',
  updated_at: '',
  has_financial_bpo: false,
  financial_emails: [],
  financial_phones: [],
  api_data: { ...DEFAULT_API_DATA },
  state_code: '',
  municipal_code: '',
  bank_data: { ...DEFAULT_BANK_DATA },
  dominio_folder: '',
  type_of_establishment: null,
  aedf: '',
  share_capital: 0,
  nf_requirements: '',
  is_concierge: false,
  plan_id: null,
  new_plan: null, // Campo auxiliar
  on_cash_basis: false
}

export const companyApiDataFields = [
  [
    'abertura', 'abertura',
    val => /^\d{2}\/\d{2}\/\d{4}$/.test(val) ? val.split('/').reverse().join('-') : ''
  ],
  'atividade_principal', 'atividades_secundarias',
  ['cep', 'cep', val => clearDigits(val)],
  'bairro',
  ['capital_social', 'capital_social', val => parseFloat(val)],
  ['cnpj', 'cnpj', val => clearDigits(val)],
  'complemento',
  'email',
  'fantasia',
  'logradouro',
  'municipio',
  'natureza_juridica',
  'nome',
  'numero',
  [
    'telefone',
    'telefone',
    val => String(val).split('/').map(val => clearDigits(val))
  ],
  'tipo',
  'uf',
  [
    'data_situacao',
    'data_situacao',
    val => formatDate(val, 'YYYY-MM-DD', 'DD/MM/YYYY')
  ],
  [
    'data_situacao_especial',
    'data_situacao_especial',
    val => val ? formatDate(val, 'YYYY-MM-DD', 'DD/MM/YYYY') : ''
  ]
]

export const DOCUMENT_AWAITING_UPLOAD = 'Aguardando'
export const DOCUMENT_UPLOADING = 'Enviando'
export const DOCUMENT_PROCESSING = 'Processando'
export const DOCUMENT_UPLOAD_ERROR = 'Erro'
export const DOCUMENT_UPLOAD_SUCCESS = 'Sucesso'
export const DOCUMENT_UPLOAD_CANCEL = 'Cancelado'

export const documentStatusColors = {
  [DOCUMENT_AWAITING_UPLOAD]: 'grey',
  [DOCUMENT_UPLOADING]: 'primary',
  [DOCUMENT_PROCESSING]: 'primary',
  [DOCUMENT_UPLOAD_ERROR]: 'warning',
  [DOCUMENT_UPLOAD_SUCCESS]: 'positive',
  [DOCUMENT_UPLOAD_CANCEL]: 'grey'
}

export const statusOptions = [
  {
    label: 'Ativo',
    value: true
  },
  {
    label: 'Inativo',
    value: false
  }
]
export const TAX_REGIME_SIMPLE = 'Simples Nacional'
export const TAX_REGIME_SIMPLE_EXCESS = 'Simples Nacional - Excesso de sublimite de receita bruta'
export const TAX_REGIME_NORMAL = 'Regime Normal'

export const taxRegimeOptions = [TAX_REGIME_SIMPLE, /* TAX_REGIME_SIMPLE_EXCESS, */ TAX_REGIME_NORMAL]

export const ACTIVE = 'Ativo'
export const INACTIVE = 'Inativo'
export const FROZEN = 'Congelado'
export const DISCHARGED = 'Baixado'
export const DEFAULTER = 'Inadimplente'

export const COMPANY_STATUS = [
  ACTIVE,
  INACTIVE,
  FROZEN,
  DISCHARGED,
  DEFAULTER
]

export const COMPANY_STATUS_PROPS = {
  [ACTIVE]: 'positive',
  [INACTIVE]: 'red-6',
  [DISCHARGED]: 'grey-6',
  [FROZEN]: 'blue-5',
  [DEFAULTER]: 'orange-6'
}

export const COMPANY_STATUS_BANNER_PROPS = {
  // [ACTIVE]: {
  //   color: '',
  //   label: ''
  // },
  [INACTIVE]: {
    color: 'negative',
    label: 'Essa empresa está inativa'
  },
  [FROZEN]: {
    color: 'blue-5',
    label: 'Essa empresa está congelada'
  },
  [DISCHARGED]: {
    color: 'negative',
    label: 'Esse CNPJ não existe mais. Essa empresa foi baixada.'
  },
  [DEFAULTER]: {
    color: 'negative',
    label: 'Essa empresa está inadimplente'
  }
}

export const FINANCIAL_BPO_EMAIL = 'bpo@docstage.com.br'

export const CLINIC = 'Clínica'
export const MEDICAL_OFFICE = 'Consultório'

export const ESTABLISHMENT_TYPES_OPTIONS = [
  CLINIC,
  MEDICAL_OFFICE
]

export const SHOW_DEFAULTER_BANNER_AFTER_N_DAYS = 10

export const PIX_TYPE_EMAIL = 'E-mail'
export const PIX_TYPE_CPF_CNPJ = 'CPF/CNPJ'
export const PIX_TYPE_PHONE = 'Telefone'
export const PIX_TYPE_RANDOM_KEY = 'Chave Aleatória'

export const PIX_TYPES = [
  PIX_TYPE_EMAIL,
  PIX_TYPE_CPF_CNPJ,
  PIX_TYPE_PHONE,
  PIX_TYPE_RANDOM_KEY
]

export const PIX_TYPE_RANDOM_KEY_MAX_LENGTH = 32
export const PIX_TYPE_RANDOM_KEY_REGEX = /^[0-9A-Fa-f]+$/

export const STATUS_ACCOUNTING_ACTIVE = 'Ativo'
export const STATUS_ACCOUNTING_FROZEN = 'Congelado'
export const STATUS_ACCOUNTING_DISCHARGED = 'Baixado'
export const STATUS_ACCOUNTING_TRANSFERRED = 'Transferido'
export const STATUS_ACCOUNTING_IN_DISCHARGE = 'Em Baixa'
export const STATUS_ACCOUNTING_IN_TRANSFER = 'Em Transferência'

export const STATUS_ACCOUNTING_OPTIONS = [
  STATUS_ACCOUNTING_ACTIVE,
  STATUS_ACCOUNTING_FROZEN,
  STATUS_ACCOUNTING_DISCHARGED,
  STATUS_ACCOUNTING_TRANSFERRED,
  STATUS_ACCOUNTING_IN_DISCHARGE,
  STATUS_ACCOUNTING_IN_TRANSFER
]

export const STATUS_ACCOUNTING_OPTIONS_PROPS = {
  [STATUS_ACCOUNTING_ACTIVE]: {
    color: 'positive',
    label: STATUS_ACCOUNTING_ACTIVE
  },
  [STATUS_ACCOUNTING_FROZEN]: {
    color: 'blue-5',
    label: STATUS_ACCOUNTING_FROZEN
  },
  [STATUS_ACCOUNTING_DISCHARGED]: {
    color: 'grey-6',
    label: STATUS_ACCOUNTING_DISCHARGED
  },
  [STATUS_ACCOUNTING_TRANSFERRED]: {
    color: 'cyan-7',
    label: STATUS_ACCOUNTING_TRANSFERRED
  },
  [STATUS_ACCOUNTING_IN_DISCHARGE]: {
    color: 'orange-7',
    label: STATUS_ACCOUNTING_IN_DISCHARGE
  },
  [STATUS_ACCOUNTING_IN_TRANSFER]: {
    color: 'purple-4',
    label: STATUS_ACCOUNTING_IN_TRANSFER
  }
}
