const orderX = {
  asc: 1,
  desc: -1
}

const localeOptions = {
  sensitivity: 'base'
}

const getOrderX = order => {
  if (!Object.prototype.hasOwnProperty.call(orderX, order)) {
    throw new Error('Modo de ordenação inválido')
  }

  return orderX[order]
}

export const sortByObjectString = (prop, order = 'asc') => {
  const x = getOrderX(order)

  return (a, b) =>
    String(a[prop]).localeCompare(String(b[prop]), 'pt-BR', localeOptions) * x
}

export const sortByString = (order = 'asc') => {
  const x = getOrderX(order)

  return (a, b) =>
    String(a).localeCompare(String(b), 'pt-BR', localeOptions) * x
}

export const sortByObjectNumber = (prop, order = 'asc') => {
  const x = getOrderX(order)

  return (a, b) => (Number(a[prop]) - Number(b[prop])) * x
}

export const sortByNumber = (order = 'asc') => {
  const x = getOrderX(order)

  return (a, b) => (Number(a) - Number(b)) * x
}

/**
 * @param {Array|Record<any, number>} priority
 * @param {*} order
 */
export const sortByPriority = (priority, order = 'asc') => {
  if (Array.isArray(priority)) {
    priority = Object.fromEntries(priority.map((el, i) => [el, i]))
  }

  const x = getOrderX(order)

  return (a, b) =>
    (Number(priority[a] ?? Infinity) - Number(priority[b] ?? Infinity)) * x
}

/**
 * @param {string} prop
 * @param {Array|Record<any, number>} priority
 * @param {*} order
 */
export const sortByPriorityObject = (prop, priority, order = 'asc') => {
  if (Array.isArray(priority)) {
    priority = Object.fromEntries(priority.map((el, i) => [el, i]))
  }

  const x = getOrderX(order)

  return (a, b) =>
    (Number(priority[a[prop]] ?? Infinity) -
      Number(priority[b[prop]] ?? Infinity)) *
    x
}

export const sortByDate = (order = 'asc') => {
  const x = getOrderX(order)

  return (a, b) => {
    if (typeof a === 'string') a = new Date(a)
    if (typeof b === 'string') b = new Date(b)

    if (!a?.getTime()) return 1
    if (!b?.getTime()) return -1

    return (a.getTime() - b.getTime()) * x
  }
}

export const sortByObjectDate = (prop, order = 'asc') => {
  const x = getOrderX(order)

  return (a, b) => {
    let a1 = a[prop]
    let b1 = b[prop]

    if (typeof a1 === 'string') a1 = new Date(a1)
    if (typeof b1 === 'string') b1 = new Date(b1)

    if (!a1?.getTime()) return 1
    if (!b1?.getTime()) return -1

    return (a1.getTime() - b1.getTime()) * x
  }
}
