import { api } from 'src/boot/axios'
import { STEPS_PRIORITY } from 'src/constants/signup'
import { sortByPriorityObject } from 'src/utils/sort'

export async function loadCompanies ({ commit, getters }, { companyId, setCompany = false } = {}) {
  try {
    commit('setCompaniesLoading', true)

    const { data } = await api.get('companies')

    commit('setCompanies', data)

    if (setCompany) {
      companyId ??= getters.getCompany?.id
      const currentCompany = data.find(company => company.id === companyId)

      if (currentCompany) {
        commit('setCompany', currentCompany)
      } else {
        const prioritizedCompanies = data.slice().sort(sortByPriorityObject('wizard_step', STEPS_PRIORITY))
        commit('setCompany', prioritizedCompanies[0] ?? null)
      }
    }
  } finally {
    commit('setCompaniesLoading', false)
  }
}

export async function setCompanyById ({ commit }, companyId) {
  const { data } = await api.get(`company/${companyId}`)

  commit('setCompany', data)

  return data
}
