import { date } from 'quasar'
import { cnpjMask, cpfMask, barCodeMask, pixRandomKeyMask } from './masks'
import { permissionTypes } from 'src/constants/users'
import { PIX_TYPE_CPF_CNPJ, PIX_TYPE_EMAIL, PIX_TYPE_PHONE, PIX_TYPE_RANDOM_KEY } from 'src/constants/companies'

/**
 * Prettify a time string
 * @param {string} time Time string in HH:mm format (/^\d{1,2}:\d{1,2}$/)
 *
 * @returns {string} Formatted time string
 */
export const prettifyTime = (time) => {
  if (/^\d{1,2}:\d{1,2}$/.exec(time) === null) {
    return '--:--'
  }
  const [hours, mins] = time.split(':').map(t => parseInt(t))

  let s = `${hours}h`

  if (mins > 0) s += ` ${mins}min`

  return s
}

/**
 * Prettify a minutes number to formatted hours string
 * @param {number} minutes
 *
 * @returns {string} Formatted hours string
 */
export const prettifyMinutes = (minutes) => {
  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60

  let s = ''

  if (hours > 0) s += `${hours}h`
  if (mins > 0) s += ` ${mins}min`

  return s
}

/**
 * Applies a mask to a string value
 *
 * @param {any} value Value to be formatted with the pattern
 * @param {string} pattern Mask pattern
 *
 * Pattern examples:
 * - ###.###.###-##
 * - ##.###-###
 * - (##) #####-####
 *
 * @returns {string} Formatted string
 */
export const formatMask = (value, pattern) => {
  if (!value) return ''
  const v = String(value)
  let i = 0
  return pattern.replace(/#/g, () => v[i++] || '')
}

export const clearDigits = (value) => {
  return String(value).replace(/\D/g, '')
}

/**
 * Appends http to url
 * @param {string} url Url string
 */
export const formatUrl = (url) => {
  if ((/^(http(s)?:\/\/)/).exec(url) === null) url = 'http://' + url

  return url
}

/**
 * Formats a api recaptcha error message
 * @param {string} errorMessage Error message
 */
export const formatRecaptchaErrorMessage = (errorMessage) => {
  const colonIndex = errorMessage.indexOf(':')
  let message =
    'Ocorreu um erro ao validar o captcha, por favor tente novamente.'
  if (colonIndex > -1) {
    // https://developers.google.com/recaptcha/docs/verify#error_code_reference
    switch (errorMessage.slice(colonIndex + 1).trim()) {
      case 'timeout-or-duplicate':
        message = 'A verificação do captcha expirou, por favor tente novamente.'
        break
    }
  }
  return message
}

export const romanToInt = (s) => {
  const sym = {
    I: 1,
    V: 5,
    X: 10,
    L: 50,
    C: 100,
    D: 500,
    M: 1000
  }

  let result = 0

  for (let i = 0; i < s.length; i++) {
    const cur = sym[s[i]]
    const next = sym[s[i + 1]]

    if (cur < next) {
      result += next - cur
      i++
    } else {
      result += cur
    }
  }

  return result
}

export const intToRoman = (num) => {
  const map = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1
  }
  let result = ''

  for (const key in map) {
    const repeatCounter = Math.floor(num / map[key])

    if (repeatCounter !== 0) {
      result += key.repeat(repeatCounter)
    }

    num %= map[key]

    if (num === 0) return result
  }

  return result
}

/**
 *
 * @param {string} d Date string
 * @param {string} dateFormat Date Formats
 * @param {string} [dateMask] Original Date Masks
 * @returns {string} Formatted Date String
 */
export const formatDate = (d, dateFormat, dateMask) => {
  if (!d) return null

  return date.formatDate(
    dateMask
      ? date.extractDate(d, dateMask)
      : new Date(d),
    dateFormat
  )
}

export const normalize = str => {
  if (typeof str === 'number') return String(str)
  if (typeof str !== 'string') return ''
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toUpperCase()
}

/**
 * Formats a number to locale
 * @param {number} number Number to be formatted
 * @param {Object} options Options https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#parameters
 */
export const formatNumberLocale = (number, options) => {
  const defaults = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }
  const { minimumFractionDigits, maximumFractionDigits } = Object.assign(
    defaults,
    options
  )

  if (typeof number === 'undefined' || number === null) return null
  if (typeof number === 'string') number = Number(number)
  if (isNaN(number)) return null

  if (typeof maximumFractionDigits === 'undefined') {
    defaults.maximumFractionDigits = minimumFractionDigits
  }

  return number.toLocaleString('pt-BR', defaults)
}

export const formatISORfc3339 = dt => {
  return date.formatDate(dt, 'YYYY-MM-DD[T]HH:mm:ssZ')
}

export const formatCNPJorCPF = str => {
  if (typeof str !== 'string') return null

  str = str.replace(/\D/g, '')

  if (str.trim().length === 14) {
    return formatMask(str, cnpjMask)
  } else if (str.trim().length === 11) {
    return formatMask(str, cpfMask)
  }

  return str
}

export const formatMoney = (val, locale = 'pt-BR', opts = {}) => {
  const defaults = {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits: 2
  }
  const options = Object.assign(defaults, opts)

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    ...options
  })

  return formatter.format(val)
}

export const formatPhone = str => {
  if (typeof str !== 'string') return null

  str = str.replace(/\D/g, '').trim()

  let ddi
  if (str.length >= 12) {
    ddi = str.substring(0, 2)
    str = str.substring(2)
  }

  const formatted = str.length <= 10
    ? str.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
    : str.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')

  return ddi ? `+${ddi} ${formatted}` : formatted
}

export const formatPixKey = (key, type) => {
  switch (type) {
    case PIX_TYPE_CPF_CNPJ: {
      return formatCNPJorCPF(key)
    }
    case PIX_TYPE_PHONE: {
      return formatPhone(key)
    }
    case PIX_TYPE_EMAIL: {
      return key
    }
    case PIX_TYPE_RANDOM_KEY: {
      return formatPixRandomKey(key)
    }
    default: {
      return key
    }
  }
}

export const formatPixRandomKey = str => {
  if (typeof str !== 'string') return null
  str = str.replace(/[^a-z0-9]/gi, '')

  const maskWithHash = pixRandomKeyMask.replace(/x/g, '#')

  return formatMask(str, maskWithHash)
}

export const formatPercentage = (value, precision = 2) => {
  if (typeof value === 'string') value = Number(value)
  if (typeof value !== 'number' || isNaN(value)) return null

  return value.toLocaleString('pt-BR', {
    maximumFractionDigits: precision
  }) + '%'
}

export const formatWhatsAppUrl = (phoneNumber, message) => {
  return `https://wa.me/${clearDigits(phoneNumber)}?text=${encodeURIComponent(message)}`
}

/**
 * Dado como entrada um array, formata para uma string com os elementos separados
 * por vírgula com o último separador sendo uma conjunção ou disjunção (e.g. "a, b, c and d")
 *
 * Exemplos usando conjunção "e" (type = 'conjunction'):
 * ['a'] -> 'a'
 * ['a', 'b'] -> 'a and b'
 * ['a', 'b', 'c'] -> 'a, b and c'
 *
 * Referência para verificar os valores possíveis dos parâmetros
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/ListFormat
 *
 * @param {array} arr
 * @param {string} type
 * @param {string} locale
 * @param {string} style
 * @returns {string}
 */
export const arrayJoinJunction = (arr, { type = 'conjunction', locale = 'pt-BR', style = 'short' } = {}) => {
  const arrToString = arr.map(item => typeof item === 'string' ? item : String(item))

  const formatter = new Intl.ListFormat(locale, { style, type })

  return formatter.format(arrToString)
}

export const formatBarCode = str => {
  if (typeof str !== 'string') return null

  str = str.replace(/\D/g, '').trim()

  return formatMask(str, barCodeMask(str))
}

export const formatNameAndDocnum = (name, docnum, { noFormatDocnum = false } = {}) => {
  if (!name && !docnum) return ''

  if (!docnum) return name

  const formattedDocnum = !noFormatDocnum
    ? formatCNPJorCPF(docnum)
    : docnum

  if (!name) return formattedDocnum

  return name + (docnum ? ` (${formattedDocnum})` : '')
}

export const formatRolePermissions = (roles) => {
  return Object.entries(roles).map(([name, permissions]) => {
    return {
      name,
      ...Object.fromEntries(permissionTypes.map(permission => [permission.value, permissions.includes(permission.value)]))
    }
  })
}

export const formatYesOrNo = (val) => {
  if (typeof val !== 'boolean') return null

  return val ? 'Sim' : 'Não'
}
